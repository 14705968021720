import React from 'react';
import {storiesCaptionClasses, StyledStoriesCaption} from "./StyledStoriesCaption";
import {Container, Grid, Typography, Button} from "@mui/material";
import {primary} from "../../common/theme";
import storiesHero from "../../assets/storiesHero.jpg";
import { StoriesFilter } from '../../Components/StoriesFilter';

export const StoriesCaption = () => {

    const gridContainerStyle = {
        display: 'none',
        position: 'relative',
        paddingTop: '30px',
        marginLeft: '-20px',
        marginBottom: '25px',
        paddingLeft: '20px',
        backgroundSize: '80%',
    };

    const mainContainerStyle = {
        backgroundImage: 'url('+storiesHero+')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    return <StyledStoriesCaption className={storiesCaptionClasses.root}>
        <Grid item sx={mainContainerStyle} className="heroClass">
            <Container maxWidth={"lg"}>
                <Grid container className={storiesCaptionClasses.mainContainer} sx={{ minHeight: { lg: "calc(100vh - 228px)" } }}>
                    <Grid item md={8} lg={6}>
                        <Grid container sx={{position: "relative", backgroundColor:"rgba(255,255,255,0.75)", padding:"40px 20px 20px", marginBottom: "40px" }}>
                            <Typography variant={"caption"} component={"div"} sx={{color: "#011E3F", textAlign: "left", zIndex: 100,  position: "relative"}}>
                                Stories by Country
                            </Typography>
                            <Typography sx={{color: "#011E3F", margin: "30px 0 20px", textAlign: "left", fontSize: "20px"}}>
                                Have a quick read of stories from all across the world on "how to" integrate SRHR within UHC in various settings.
                            </Typography>
                        </Grid>

                        <StoriesFilter />

                        <Grid container sx={gridContainerStyle}>
                            <Grid container sx={{position: "relative", paddingTop: "30px"}}>
                                <Typography component={"div"} sx={{color: "white", textAlign: "left", zIndex: 100,  position: "relative", fontSize: "28px", lineheight: "32px", fontWeight: "500"}}>
                                    Call for Implementation Stories
                                </Typography>
                            </Grid>
                            <Typography variant={"h3"} sx={{color: "white", margin: "10px 0 30px 0", textAlign: "left", fontSize: "18px", lineheight: "28px"}}>
                                Deadline 15th August 2023
                            </Typography>
                            <Typography variant={"h3"} sx={{color: "white", margin: "10px 0 30px 0", textAlign: "left", fontSize: "18px", lineheight: "28px", maxWidth: "90%"}}>
                                Submit a story and contribute to the universal health coverage agenda and guide national-level decision-makers
                            </Typography>
                            <Button variant={"contained"} sx={{color: primary, backgroundColor: "#E38A15", borderRadius: "10px", padding: "10px 20px", '&:hover': { color: '#FFFFFF' },}}
                                        href={"https://www.who.int/news-room/articles-detail/implementation-stories-sharing-portal-sexual-and-reproductive-health-and-rights-and-uhc"} target="_blank" rel="noreferrer">
                                Submit a Story
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* FIXME: Dont do this*/}
                <Grid sx={{"height": "50px", width: "100%"}} />
            </Container>
        </Grid>
        <Grid item className={storiesCaptionClasses.subBar}>
            <Container maxWidth={"xl"}>
                <Grid container sx={{padding: "15px 0", position: "relative" }}>
                    <Grid item lg={12}>
                        <Grid container sx={{position: "relative", padding:"0", justifyContent: "center" }}>
                            <Typography variant={"h3"} sx={{color: "#FFF", fontWeight:"bold", margin: "0", textAlign: "center", fontSize: "20px", maxWidth: "990px"}}>
                            Please join us for our very first webinar on strengthening adolescent-responsive health systems on 5&nbsp;November at 15:00 CET. Register here: <a href="https://bit.ly/3BVhfyd" target="_blank" style={{color: "#FFF"}} rel="noreferrer">https://bit.ly/3BVhfyd</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </StyledStoriesCaption>;
};
