import React from 'react';
import {footerClasses as classes, StyledFooter} from "./StyledFooter";
import {Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import whoBlue from "../Header/icons/who-blue.png";
import hrpBlue from "../Header/icons/hrp-blue.png";
import unfpaColor from "../Header/icons/unfpa-color.png";
import {useNavigate} from "react-router-dom";
import {customNavigate, links, pages} from "../routes";

export const Footer = () => {
    const navigate = useNavigate();
    return <StyledFooter className={classes.root}>
        <Container maxWidth={"lg"} className={classes.container}>
            <Grid container className={classes.topContainer}>
                <Grid item xs={12} lg={6} display={"flex"} sx={{marginBottom: "40px"}}>
                    <Grid container>
                        <img className={classes.logo} src={whoBlue} width={138} height={43} alt={"Who Blue"}/>
                        <img className={classes.logo} src={hrpBlue} width={99} height={44} alt={"Hrp Blue"}/>
                        <img className={classes.logo} src={unfpaColor} width={92} height={43} alt={"Unfpa Light"}/>
                    </Grid>
                </Grid>
                <Grid item sm={6} lg={3} sx={{marginBottom: "20px"}}>
                    {pages.map((page, index)=>(
                        <Typography
                            key={page}
                            variant="h6"
                            noWrap
                            component="div"
                            onClick={()=>customNavigate(navigate, links[index])}
                            className={classes.navigation}
                            sx={{ mr: 2, display: { xs: 'flex' } }}>
                            {page}
                        </Typography>
                    ))}
                </Grid>
                <Grid container item sm={6} lg={3} display={"flex"} direction={"column"} alignItems={"flex-start"}>
                    <Typography variant={"h6"} component={"div"} className={classes.navigation}>
                        Contacts
                    </Typography>
                    <Typography variant={"h6"}
                                component={"a"}
                                className={classes.bottomContainerTextRight}
                                href={"mailto:srh-uhc-lsp@who.int"}>
                        Email us
                    </Typography>
                    <Typography variant={"h6"}
                                component={"a"}
                                className={classes.bottomContainerTextRight}
                                href={"https://twitter.com/HRPresearch"}>
                        HRP Twitter
                    </Typography>
                    <Typography variant={"h6"}
                                component={"a"}
                                className={classes.bottomContainerTextRight}
                                href={"https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)"}>
                        HRP Website
                    </Typography>
                    <Typography variant={"h6"}
                                component={"a"}
                                className={classes.bottomContainerTextRight}
                                href={"https://twitter.com/unfpa"}>
                        UNFPA Twitter
                    </Typography>
                    <Typography variant={"h6"}
                                component={"a"}
                                className={classes.bottomContainerTextRight}
                                href={"https://www.unfpa.org/"}>
                        UNFPA Website
                    </Typography>
                </Grid>
            </Grid>
            </Container>
            <Grid container className={classes.joinUsRibbon}>
                <Container maxWidth={"lg"}>
                    <Grid container className={classes.joinUsRibbonContainer}>
                        <Grid item lg={12}>
                            <Typography variant={"h6"} className={classes.bottomContainerTextMail}>
                                We welcome the submission of stories on a rolling basis, get in touch via <a href="mailto:srh-uhc-lsp@who.int" className="bottomContainerTextMailLink" >srh-uhc-lsp@who.int</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid container className={classes.bottomWrapper}>
                <Container maxWidth={"lg"}>
                    <Grid container
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          className={classes.bottomContainer}>
                        <Grid item lg={3} display={"flex"}>
                        </Grid>
                        <Grid item lg={3} display={"flex"} justifyContent={"flex-end"}>
                            <Typography variant={"h6"}
                                        component={"a"}
                                        className={classes.bottomContainerTextRight}
                                        href={"https://www.who.int/about/policies/privacy"}>
                                Privacy Policy
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
    </StyledFooter>;
};
