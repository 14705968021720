import React from "react";
import { Mode } from "../interfaces/mode";
import { resourcesClasses as classes, StyledResources } from "./StyledResources";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { primary } from "../common/theme";
import zero from "./images/zero.png";
import first from "./images/1.png";
import second from "./images/2.png";
import third from "./images/3.png";
import forth from "./images/4.png";
import fifth from "./images/5.png";
import sixth from "./images/6.png";
import seventh from "./images/7.png";
import eight from "./images/8.png";
import ninth from "./images/9.png";
import ten from "./images/10.jpg";
import thumbinvest from "./images/thumbinvest.png";
import th_nail1 from "./images/thumb_1.png";
import th_nail2 from "./images/thumb_2.jpg";
import th_nail3 from "./images/thumb_3.jpg";
import ArticleIcon from '@mui/icons-material/Article';
import { useGA } from "../common/hooks";
import resourcesHero from "../assets/resourcesHero.jpg";

interface Resource {
    year: string;
    title: string;
    link: string;
    image: any;
}

const resources: Resource[] = [
    {
        year: "2024",
        title: "Unlocking the Future: Advancing Universal Sexual and Reproductive Health and Rights within Universal Health Coverage in East and Southern Africa",
        link: "https://esaro.unfpa.org/en/publications/unlocking-future-advancing-universal-sexual-and-reproductive-health-and-rights-within-0",
        image: th_nail1,
    },
    {
        year: "2023",
        title: "Role of strategic purchasing in the integration of sexual and reproductive health services into primary health care: evidence brief",
        link: "https://www.who.int/publications/i/item/9789240087859",
        image: th_nail2,
    },
    {
        year: "2023",
        title: "Potential for strategic purchasing to promote person-centred provision of sexual and reproductive health services in low- and middle-income countries: evidence brief",
        link: "https://www.who.int/publications/i/item/9789240087835",
        image: th_nail3,
    },
    {
        year: "2023",
        title: "Investing in sexual and reproductive health and rights: essential elements of universal health coverage",
        link: "https://www.who.int/publications/m/item/investing-in-sexual-and-reproductive-health-and-rights-essential-elements-of-universal-health-coverage",
        image: thumbinvest,
    },
    {
        year: "2022",
        title: "Sexual and reproductive health and rights: infographic snapshot",
        link: "https://www.who.int/publications/i/item/WHO-SRH-21.21",
        image: ten,
    },
    {
        year: "2022",
        title: `Critical considerations and actions for
achieving universal access to sexual and
reproductive health in the context of
universal health coverage through a
primary health care approach`,
        link: "https://apps.who.int/iris/handle/10665/357614",
        image: zero,
    },
    {
        year: "2021",
        title: "Evidence brief: universal health coverage for sexual and reproductive health in Ghana",
        link: "https://www.who.int/publications/i/item/WHO-SRH-21.17",
        image: first,
    },
    {
        year: "2021",
        title: "Evidence brief: universal health coverage for sexual and reproductive health in Malaysia",
        link: "https://www.who.int/publications/i/item/WHO-SRH-21.18",
        image: second,
    },
    {
        year: "2021",
        title: "Evidence brief: universal health coverage for sexual and reproductive health in Morocco",
        link: "https://apps.who.int/iris/bitstream/handle/10665/352099/9789240044623-eng.pdf",
        image: third,
    },
    {
        year: "2021",
        title: "Sexual and reproductive health interventions in the WHO UHC Compendium\n",
        link: "https://www.who.int/publications/i/item/9789240022867",
        image: forth,
    },
    {
        year: "2020",
        title: "Evidence brief: financing sexual and reproductive health services under universal health coverage",
        link: "https://apo.who.int/publications/i/item/universal-health-coverage-for-sexual-and-reproductive-health?msclkid=1f495af1c66f11ec9be8d9db971d34f2",
        image: fifth,
    },
    {
        year: "2019",
        title: "Sexual & Reproductive Health UHC Guide",
        link: "https://eeca.unfpa.org/sites/default/files/pub-pdf/86_SRH%20UHC%20Guide%202019.pdf",
        image: sixth,
    },
    {
        year: "2019",
        title: "Sexual and Reproductive Health and Rights: An Essential Element of Universal Health Coverage. Background document for the Nairobi summit on ICPD25 – Accelerating the promise",
        link: "https://www.unfpa.org/sites/default/files/pub-pdf/SRHR_an_essential_element_of_UHC_2020_online.pdf",
        image: seventh,
    },
    {
        year: "2019",
        title: "Supplement to Background paper on Sexual and Reproductive Health and Rights: An Essential Element of Universal Health Coverage",
        link: "https://www.unfpa.org/resources/supplement-background-paper-sexual-and-reproductive-health-and-rights-essential-element",
        image: eight,
    },
    {
        year: "2018",
        title: "Call to action to attain universal health coverage through linked sexual and reproductive health and rights and HIV interventions",
        link: "https://www.who.int/publications/i/item/WHO-RHR-18.13?msclkid=bfa0f3dac66f11ec999529b7bfa67f40",
        image: ninth,
    },
];

export const Resources = () => {
    useGA("Resources");

    const mainContainerStyle = {
        backgroundImage: 'url('+resourcesHero+')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    const barContainerStyle = {
        backgroundColor: '#009ADE',
        textAlign: 'center',
    }

    return <ViewContainer mode={Mode.Dark}>
        <StyledResources className={classes.root}>
            
            <Grid item sx={mainContainerStyle} className="heroClass">
                <Container maxWidth={"lg"}>
                    <Grid container sx={{paddingTop: "150px", paddingBottom: "55px", position: "relative", minHeight: {lg: "calc(100vh - 228px)"} }}>
                        <Grid item md={8} lg={5}>
                            <Grid container sx={{position: "relative", backgroundColor:"rgba(255,255,255,0.75)", padding:"40px 20px 20px", marginBottom: "40px" }}>
                                <Typography variant={"caption"} component={"div"} sx={{color: "#011E3F", textAlign: "left", zIndex: 100,  position: "relative"}}>
                                Resources
                                </Typography>
                                <Typography sx={{color: "#011E3F", margin: "30px 0 20px", textAlign: "left", fontSize: "20px"}}>
                                Access the latest resources on sexual and reproductive health and rights and universal health coverage from WHO and UNFPA.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* FIXME: Dont do this*/}
                    <Grid item sx={{"height": "50px", width: "100%"}} />
                </Container>
            </Grid>
            <Grid item sx={barContainerStyle}>
                <Container maxWidth={"xl"}>
                    <Grid container sx={{padding: "15px 0", position: "relative" }}>
                        <Grid item lg={12}>
                            <Grid container sx={{position: "relative", padding:"0", justifyContent: "center" }}>
                                <Typography variant={"h3"} sx={{color: "#FFF", fontWeight:"bold", margin: "0", textAlign: "center", fontSize: "20px", maxWidth: "990px"}}>
                                Please join us for our very first webinar on strengthening adolescent-responsive health systems on 5&nbsp;November at 15:00 CET. Register here: <a href="https://bit.ly/3BVhfyd" target="_blank" style={{color: "#FFF"}} rel="noreferrer">https://bit.ly/3BVhfyd</a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            <Container fixed>
                <Grid container sx={{margin: "50px 0 150px 0"}}>
                    { resources.map((resource)=>(
                        <Grid item md={6} lg={4} key={resource.link}>
                            <Card sx={{ maxWidth: "350", margin: "10px 20px 50px 20px" }} className={classes.card}>
                                <CardActionArea sx={{position: "relative"}}>
                                    <CardMedia
                                        component="img"
                                        className={classes.cardImg}
                                        image={resource.image}
                                        alt={'Resource'}
                                    />

                                    <CardContent className={classes.cardContent}>
                                        <Grid container>
                                            <Typography gutterBottom sx={{
                                                fontSize: "24px",
                                                display: "flex",
                                                alignItems: "center",
                                                color: primary,
                                                margin: "5px 0",
                                                fontWeight: "bold"
                                            }}>
                                                <ArticleIcon sx={{ width: "40px", height: "40px" }} />
                                                {resource.year}
                                            </Typography>
                                            <Typography gutterBottom sx={{ fontSize: "17px", lineHeight: "22px", color: primary, fontWeight: "500", margin: "5px 0 20px" }} className="resourceHeader">
                                                {resource.title}
                                            </Typography>
                                        </Grid>
                                        <Grid container>
                                            <Button onClick={()=>{
                                                window.open(resource.link, '_blank');
                                            }} variant={"contained"} className={classes.button} >

                                                Read more
                                            </Button>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </StyledResources>;
    </ViewContainer>
};
